var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('endorsements.endorsements'),"description":_vm.$t('endorsements.fromHereYouCanControlTheendorsements')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('endorsements.SearchForAStatement'),"emptyTableText":_vm.$t('endorsements.thereAreNoendorsements'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.endorsements_add,"cloumns":[
                   {column: 'name',    title:_vm.$t('endorsements.recipientsName') ,type: 'mainLink' , sort: true },
                   {column: 'identification_number',  title:_vm.$t('endorsements.identificationNumber')  ,type: 'text'     , sort: true },
                   {column: 'date', title:_vm.$t('endorsements.endorsementsDate')      ,type: 'text'     , sort: true},
                   {column: 'notes',    title:_vm.$t('endorsements.note')       ,type: 'text'     , sort: true },
                   {column: 'options', title:_vm.$t('endorsements.settings'), type: 'options', options: [
                       {name: 'show'},
                       {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.endorsements_edit},
                       {name: 'print',   role: true},
                       {name: 'delete', role: _vm.$user.admin || _vm.$user.role.endorsements_delete} ]}
                 ],"deleteText":{
                  attention: _vm.$t('allerts.Attention'),
                  areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                  close: _vm.$t('allerts.close'),
                  confirm: _vm.$t('allerts.confirm'),
                  loading: _vm.$t('allerts.loading'),
                 }}})],1)]),(_vm.printInvoice)?_c('print'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }