<template>

  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('endorsements.endorsements')"
                     :description="$t('endorsements.fromHereYouCanControlTheendorsements')"/>
         <indexTable :searchText="$t('endorsements.SearchForAStatement')"
                    :emptyTableText="$t('endorsements.thereAreNoendorsements')"
                    :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
                    :buttonRole="$user.role.endorsements_add"
                    :cloumns="[
                     {column: 'name',    title:$t('endorsements.recipientsName') ,type: 'mainLink' , sort: true },
                     {column: 'identification_number',  title:$t('endorsements.identificationNumber')  ,type: 'text'     , sort: true },
                     {column: 'date', title:$t('endorsements.endorsementsDate')      ,type: 'text'     , sort: true},
                     {column: 'notes',    title:$t('endorsements.note')       ,type: 'text'     , sort: true },
                     {column: 'options', title:$t('endorsements.settings'), type: 'options', options: [
                         {name: 'show'},
                         {name: 'edit',   role: $user.admin || $user.role.endorsements_edit},
                         {name: 'print',   role: true},
                         {name: 'delete', role: $user.admin || $user.role.endorsements_delete},
                     ]}
                   ]"
                   :deleteText="{
                    attention: $t('allerts.Attention'),
                    areYouReallySureToDeleteTheItem: $t('allerts.areYouReallySureToDeleteTheItem'),
                    close: $t('allerts.close'),
                    confirm: $t('allerts.confirm'),
                    loading: $t('allerts.loading'),
                   }" />
      </div>
    </div>
    <print v-if="printInvoice"/>
  </div>

</template>
<script>

  import indexHeader from '../elements/index/indexHeader.vue'
  import indexTable from '../elements/index/indexTable.vue'
  import print from './components/print.vue'

  export default {
    data() {
      return {
        path: '/endorsements'
      }
    },
    components: {
      indexHeader,
      indexTable,
      print
    }
  }

</script>
